/* eslint-disable @typescript-eslint/no-explicit-any */
import { ElementRef, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ClientFormUser } from '../../models/ClientFormUser';
import ClientFormService from '../../services/ClientFormService';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import { TabStrip } from '../../components/shared/tab-strip/TabStrip';
import ActivityFeed from '../../components/activity-feed/ActivityFeed';
import { ClientFormStatus } from '../../models/ClientFormStatus';
import { ClientForm } from '../../models/ClientForm';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import hasFormRole, { canCurrentUserApproveSection, canCurrentUserSubmitSection } from '../../utils/FormPermissionUtils';
import { AuditFeedItem } from '../../models/AuditFeed';
import ActivityFeedService from '../../services/ActivityFeedService';
import FormHeader from '../../components/form-header/FormHeader';
import Comments from '../../components/comments/Comments';
import { Trans, useTranslation } from 'react-i18next';
import { ApiResponse } from '../../models/ApiResponse';
import {
  ActionHighlightInfo,
  ActionPlaceholderData,
  AdHocAnswerResponse,
  AnswerResponse,
  FormAction,
  FormSection,
  PeriodicReviewChecklistItemInstance,
  Progress,
} from '../../models/Form';
import { FormType } from '../../models/FormTypes';
import { EventSystem } from '../../events/EventSystem';
import { Roles } from '../../models/Role';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import FormBreadCrumb from '../../components/form/FormBreadCrumb';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import FormRendererV2 from '../../components/form/renderer/FormRendererV2';
import { FormRendererMode } from '../../contexts/FormRendererDesignContextTypes';
import { ProgressUpdatedEvent } from '../../events/ProgressUpdatedEvent';
import Button, { ButtonSize, ButtonType } from '../../components/shared/form-control/Button';
import { ClientFormSectionStatus, sectionStatusKeys, sectionStatusToasterKeys } from '../../models/ClientFormSectionStatus';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { nextTick } from '../../utils/ReactUtils';
import FormAnswerEvent from '../../events/FormAnswerEvent';
import DateUtils from '../../utils/DateUtils';
import { deDuplicate, recordMap, toRecord } from '../../utils/ListUtils';
import { QuestionVisibilityChanged } from '../../events/QuestionVisibilityChanged';
import ObjectUtils from '../../utils/ObjectUtils';
import User from '../../models/User';
import { QuestionRequirednessChanged } from '../../events/QuestionRequirednessChanged';
import { FormSectionValidEvent } from '../../events/FormSectionValidEvent';
import { FormRendererDesignContext, FormRendererDesignDefaults } from '../../contexts/FormRendererDesignContext';
import usePermissions from '../../hooks/permissions/usePermissions';
import useOnlyHasPermission from '../../hooks/permissions/useOnlyHasPermission';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import useFormUsers from '../../hooks/useFormUsers';
import { SignalRCommentProvider } from '../../contexts/signalR/CommentContext';
import { OpenFormSectionEvent } from '../../events/OpenFormSectionEvent';
import ViewerLeftNav from '../../components/form/viewer/ViewerLeftNav';
import { useFetchAssociations } from '../../hooks/useFetchAssocations';
import DocumentBreadCrumb from '../../components/form/DocumentBreadCrumb';
import { ChevronIcon, ChevronType } from '../../components/shared/icon/ChevronIcon';
import { DocumentResponse } from '../../models/Document';
import { FileUtils } from '../../utils/FileUtils';

import DocumentFileViewer from './DocumentFileViewer';
import useFormTypeText from '../../hooks/useFormTypeText';
import { Access } from '../../models/Access';
import { useLayout } from '../../contexts/LayoutContext';
import { ActionTypeNames } from '../../components/form/ActionTypes';
import Tooltip from '../../components/shared/Tooltip';
import LanguageUtils from '../../utils/LanguageUtils';
import RiskModal from '../../components/risk/RiskModal';
import { Risk } from '../../models/Risk';
import RiskService from '../../services/RiskService';
import QuestionRiskCreatedEvent from '../../events/QuestionRiskCreatedEvent';
import { DistributionMemberStatus, DistributionResponse } from '../../models/Distribution';
import { useDetectVisible } from '../../hooks/useDetectVisible';
import DistributionLogs from '../../components/distribution/DistributionLogs';
import FormUtils from '../../utils/FormUtils';
import { useCurrentClient } from '../../global-state/Clients';
import { useCurrentActivityCommentsStats, useCurrentFormType, useFormSectionUsers, useViewDistributionLogs } from '../../global-state/Forms';
import { useCurrentUser } from '../../global-state/Auth';
import { useIsInSuspense } from '../../global-state/App';

type RouteParams = {
  formId: string;
};

const FormPage: FC = () => {
  const { formId } = useParams<RouteParams>();
  const client = useCurrentClient((x) => x.value);
  const [currentFormUser, setCurrentFormUser] = useState<ClientFormUser | null>(null);
  const [activityFeed, setActivityFeed] = useState<ApiResponse<AuditFeedItem[]>>({} as ApiResponse<AuditFeedItem[]>);
  const [formStartDate, setFormStartDate] = useState<Date | null>(null);
  const [formStatus, setFormStatus] = useState<ClientFormStatus>(ClientFormStatus.NotStarted);
  const [formProgress, setFormProgress] = useState<Progress>({ totalAnswers: 0, totalRequired: 0 });
  const [loading, setLoading] = useState(true);
  const viewDistributionLogs = useViewDistributionLogs((x) => x.value);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation(['form', 'module-list', 'common']);
  const navigate = useNavigate();
  const formRenderer = useRef<ElementRef<typeof FormRendererV2>>(null);
  const [visibleSteps, setVisibleSteps] = useState<FormSection[]>([]);
  const [isFormSectionValid, setIsFormSectionValid] = useState<Record<string, boolean>>({});
  const [commentsCollapsed, setCommentsCollapsed] = useLocalStorageState('form-viewer-right-collapsed', false);
  const [leftNavCollapsed, setLeftNavCollapsed] = useLocalStorageState('form-viewer-left-collapsed', false);
  const { leftNavRef } = useLayout();
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const toasts = useToasts();
  const toastsRef = useRef(toasts);

  const [clientForm, setClientForm] = useState<ClientForm | null>(null);
  const formSectionUsers = useFormSectionUsers((x) => x.value);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [correctedStepIndex, setCorrectedStepIndex] = useState(false);
  const [savedStepId, setSavedStepId] = useLocalStorageState<string | null>(`${formId}-step`, null);

  const [stepProgress, setStepProgress] = useState<Record<string, Progress>>({});
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const currentUser = useCurrentUser((x) => x.value);
  const hasPermission = usePermissions();
  const onlyHasPermission = useOnlyHasPermission();
  const formTypeText = useFormTypeText(clientForm?.type, t);
  const commentStats = useCurrentActivityCommentsStats((x) => x.value);
  const unreadMainComments = useMemo(() => {
    return commentStats.unreadCommentsCount;
  }, [commentStats.unreadCommentsCount]);

  const { isVisible: bottomDetectorVisible, detector: bottomDetector } = useDetectVisible();

  useEffect(
    function showPreview() {
      if (onlyHasPermission(Roles.ExternalAuditor)) {
        navigate(`/clients/${client?.id}/forms/${formId}/preview${window.location.search}${window.location.hash}`, { replace: true });
      }
    },
    [client?.id, formId, navigate, onlyHasPermission],
  );

  useEffect(
    function setWhetherFormSectionIsValid() {
      const handler = (e: FormSectionValidEvent) => {
        if (formId === e.clientFormId) {
          setIsFormSectionValid(e.sectionValid);
        }
      };
      EventSystem.listen('form-section-valid', handler);
      return () => {
        EventSystem.stopListening('form-section-valid', handler);
      };
    },
    [formId],
  );

  const toHighlight = useMemo<ActionHighlightInfo | null>(() => {
    const parts = query.get('highlight')?.split(',');
    if (!parts || parts.length !== 3) {
      return null;
    }

    const formId = parts[0];
    let sectionId = parts[1];
    const actionId = parts[2];
    if (!sectionId) {
      sectionId = clientForm?.form.sections.find((x) => x.actions.find((a) => a.id === actionId))?.id || '';
    }

    return {
      formId,
      sectionId,
      sourceId: actionId,
    };
  }, [clientForm?.form.sections, query]);

  const isSysTemplate = useMemo(
    () => clientForm?.form.isSystem && clientForm.form.type === FormType.Document,
    [clientForm?.form.isSystem, clientForm?.form.type],
  );

  const setCurrentFormType = useCurrentFormType((x) => x.setValue);

  useEffect(() => {
    return () => setCurrentFormType(null);
  }, [setCurrentFormType]);

  const [loadingFile, setLoadingFile] = useState(false);
  const fetchForm = useCallback(() => {
    let navigatePath = '';
    ClientFormService.getForm(formId as string)
      .then((res) => {
        setCurrentFormType(res.data.type);
        if (res.data.type === FormType.Resource) {
          navigatePath = `/clients/${res.data.clientId}/resources/${res.data.form.id}/${res.data.id}`;
        } else {
          const formProgress: Progress = { totalRequired: res.data.totalRequiredActions, totalAnswers: res.data.totalRequiredAnswers };
          const goToApprovalAutomatically =
            res.data.status === ClientFormStatus.Completed ||
            res.data.status === ClientFormStatus.SubmittedForApproval ||
            res.data.status === ClientFormStatus.SubmittedForValidation;
          if (goToApprovalAutomatically || res.data.originId || !!res.data.archivedUtc) {
            navigatePath = `/clients/${res.data.clientId}/forms/${formId}/preview${window.location.search}${window.location.hash}`;
          } else {
            setFormProgress(formProgress);
            setClientForm(res.data);
            if (res.data.form.isSystem && res.data.form.type === FormType.Document) {
              setLoadingFile(true);
              FileUtils.getFileUrl((res.data as DocumentResponse).fileId).then((url) => {
                setLoadingFile(false);
                setSelectedFile((prev) => (prev === url ? prev : url));
              });
            }
          }
        }
      })
      .catch(() => setCorrectedStepIndex(true))
      .finally(() => {
        if (navigatePath) {
          setTimeout(() => {
            navigate(navigatePath, { replace: true });
          }, 500);
        } else {
          setLoading(false);
        }
      });
  }, [formId, navigate, setCurrentFormType]);

  useEffect(() => {
    setLoading(true);
    setClientForm(null);
    setCurrentStepIndex(0);
    fetchForm();
  }, [fetchForm]);

  const currentTemplateStep = useMemo(() => visibleSteps[currentStepIndex], [currentStepIndex, visibleSteps]);

  const currentStepStatus = useMemo(
    () =>
      clientForm?.sectionStatuses.find((info) => info.templateFormSectionId === currentTemplateStep?.id) ?? {
        id: '-',
        templateFormSectionId: currentTemplateStep?.id,
        clientFormId: clientForm?.id,
        status: ClientFormSectionStatus.NotSubmitted,
        statusUtc: null,
        statusChangedBy: '',
      },
    [clientForm?.id, clientForm?.sectionStatuses, currentTemplateStep?.id],
  );

  useEffect(
    function onBeforeAnswer() {
      const handler = (event: FormAnswerEvent) => {
        setClientForm(
          (prev) =>
            prev && {
              ...prev,
              status: prev.status === ClientFormStatus.NotStarted ? ClientFormStatus.InProgress : prev.status,
              sectionStatuses: [
                ...prev.sectionStatuses.map((sectionStatus) => {
                  if (sectionStatus.templateFormSectionId === currentTemplateStep?.id) {
                    return { ...sectionStatus, status: ClientFormSectionStatus.NotSubmitted };
                  }
                  return sectionStatus;
                }),
              ],
              form: { ...prev.form, answers: { ...prev.form.answers, [event.actionId]: event.answer } },
            },
        );
      };

      EventSystem.listen('before-form-answer', handler);

      return () => EventSystem.stopListening('before-form-answer', handler);
    },
    [currentTemplateStep?.id],
  );

  useEffect(function onQuestionVisibilityChanged() {
    const handler = (event: QuestionVisibilityChanged) => {
      setClientForm(
        (prev) =>
          prev && {
            ...prev,
            form: {
              ...prev.form,
              sections: prev.form.sections.map((step) => ({
                ...step,
                actions: step.actions.map((action) => (event.actionId === action.id ? event.action : action)),
              })),
            },
          },
      );
    };

    EventSystem.listen('question-visibility-changed', handler);

    return () => EventSystem.stopListening('question-visibility-changed', handler);
  });

  useEffect(function onQuestionRequirednessChanged() {
    const handler = (event: QuestionRequirednessChanged) => {
      setClientForm(
        (prev) =>
          prev && {
            ...prev,
            form: {
              ...prev.form,
              sections: prev.form.sections.map((step) => ({
                ...step,
                actions: step.actions.map((action) => (event.actionId === action.id ? { ...action, required: event.isNowRequired as any } : action)),
              })),
            },
          },
      );
    };

    EventSystem.listen('question-requiredness-changed', handler);

    return () => EventSystem.stopListening('question-requiredness-changed', handler);
  }, []);

  useEffect(
    function onFormAnswered() {
      const handler = (event: AnswerResponse & { sectionId: string }) => {
        const newSectionStatus = event.clientFormId === formId ? event.sectionStatus : event.parentSectionStatus;
        const updatedPlaceholders = {} as Record<string, ActionPlaceholderData>;
        const existingPlaceholders = clientForm?.placeholders || {};
        if (existingPlaceholders) {
          for (const placeholder of Object.keys(existingPlaceholders)) {
            if (!event.placeholders.removedLookups || !event.placeholders.removedLookups[placeholder]) {
              updatedPlaceholders[placeholder] = existingPlaceholders[placeholder];
            }
          }
        }
        setClientForm(
          (prev) =>
            prev && {
              ...prev,
              subtitle: event.clientFormSubtitle,
              nextSteps: [...event.nextSteps],
              currentSteps: [...event.currentSteps],
              allSectionsValid: event.allStepsValid,
              placeholders: { ...updatedPlaceholders, ...event.placeholders.addedLookups },
              form: {
                ...prev.form,
                answers: {
                  ...prev.form.answers,
                  ...recordMap(toRecord(event.addedAnswers, 'actionId'), (_, value) => value.data),
                },
              },
              sectionStatuses: [
                ...prev.sectionStatuses.map((sectionStatus) => {
                  if (sectionStatus.templateFormSectionId === event.sectionId) {
                    return { ...sectionStatus, status: newSectionStatus };
                  }
                  return sectionStatus;
                }),
              ].concat(
                prev.sectionStatuses.find((x) => x.templateFormSectionId === event.sectionId)
                  ? ([] as any)
                  : {
                      ...currentStepStatus,
                      status: event.sectionStatus,
                      statusUtc: new Date(),
                      statusChangedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
                    },
              ),
            },
        );
      };

      EventSystem.listen('form-answer', handler);

      return () => EventSystem.stopListening('form-answer', handler);
    },
    [clientForm?.placeholders, currentStepStatus, currentUser?.firstName, currentUser?.lastName, formId],
  );

  useEffect(
    function onAdhocAnswered() {
      const handler = (event: { adHocAnswers: Record<string, AdHocAnswerResponse[]>; actionId: string; fieldId: string }) => {
        const updatedPlaceholders = {} as Record<string, ActionPlaceholderData>;
        const existingPlaceholders = clientForm?.placeholders || {};
        const placeholders = event.adHocAnswers[event.actionId].find((x) => x.fieldId === event.fieldId)?.placeholders;
        if (existingPlaceholders) {
          for (const placeholder of Object.keys(existingPlaceholders)) {
            if (!placeholders?.removedLookups || !placeholders?.removedLookups[placeholder]) {
              updatedPlaceholders[placeholder] = existingPlaceholders[placeholder];
            }
          }
        }
        setClientForm(
          (prev) =>
            prev && {
              ...prev,
              placeholders: { ...updatedPlaceholders, ...placeholders?.addedLookups },
              form: {
                ...prev.form,
                adHocAnswers: event.adHocAnswers,
              },
            },
        );
      };

      EventSystem.listen('form-ad-hoc-answer', handler);

      return () => EventSystem.stopListening('form-ad-hoc-answer', handler);
    },
    [clientForm?.placeholders, currentStepStatus, currentUser?.firstName, currentUser?.lastName, formId],
  );

  useEffect(
    function onAdhocAnswereRemoved() {
      const handler = (event: Record<string, AdHocAnswerResponse[]>) => {
        setClientForm(
          (prev) =>
            prev && {
              ...prev,
              form: {
                ...prev.form,
                adHocAnswers: { ...prev.form.adHocAnswers, ...event },
              },
            },
        );
      };

      EventSystem.listen('form-remove-ad-hoc-answer', handler);

      return () => EventSystem.stopListening('form-remove-ad-hoc-answer', handler);
    },
    [clientForm?.placeholders, currentStepStatus, currentUser?.firstName, currentUser?.lastName, formId],
  );

  useEffect(function onAdHocAnswersSorted() {
    const handler = (evt: { actionId: string; fieldIds: string[] }) => {
      setClientForm(
        (prev) =>
          prev && {
            ...prev,
            form: {
              ...prev.form,
              adHocAnswers: {
                ...prev.form.adHocAnswers,
                [evt.actionId]: evt.fieldIds.map((fieldId, i) => ({
                  ...prev.form.adHocAnswers[evt.actionId].find((x) => x.fieldId === fieldId)!,
                  sortOrder: i,
                })),
              },
            },
          },
      );
    };

    EventSystem.listen('form-ad-hoc-sorting', handler);
    return () => EventSystem.stopListening('form-ad-hoc-sorting', handler);
  }, []);

  const stepRendererModes = useMemo(() => {
    return Object.assign(
      {},
      ...(clientForm?.sectionStatuses
        .filter(
          (stepStatus) =>
            stepStatus.status !== ClientFormSectionStatus.NotSubmitted &&
            visibleSteps.find((x) => x.id === stepStatus.templateFormSectionId)?.requiresApproval,
        )
        .map((stepStatus) => {
          return { [stepStatus.templateFormSectionId]: FormRendererMode.PreviewView };
        }) || []),
    ) as Record<string, FormRendererMode>;
  }, [clientForm?.sectionStatuses, visibleSteps]);

  const canSubmitStep = useMemo(
    () => currentTemplateStep && stepProgress[currentTemplateStep.id]?.totalAnswers === stepProgress[currentTemplateStep.id]?.totalRequired,
    [currentTemplateStep, stepProgress],
  );

  useEffect(
    function setInitialProgress() {
      if (!clientForm?.id) {
        return;
      }

      const sectionProgress: Record<string, Progress> = {};
      for (const sectionId of Object.keys(clientForm.sectionProgress)) {
        const progress = clientForm.sectionProgress[sectionId];
        sectionProgress[sectionId] = {
          totalAnswers: progress.requiredAnswers + progress.requiredChildAnswers,
          totalRequired: progress.requiredActions + progress.requiredChildActions,
        };
      }
      setStepProgress(sectionProgress);
      EventSystem.fireEvent('form-progress-updated', {
        clientFormId: clientForm.id,
        clientFormProgress: { totalRequired: clientForm.totalRequiredActions, totalAnswers: clientForm.totalRequiredAnswers },
        sectionProgress: sectionProgress,
      });
    },
    [clientForm?.id, clientForm?.sectionProgress, clientForm?.totalRequiredActions, clientForm?.totalRequiredAnswers],
  );

  useEffect(() => {
    if (!clientForm) {
      return;
    }
    setFormStartDate(clientForm.startDateUtc ? new Date(clientForm.startDateUtc) : null);
    setFormStatus(clientForm.status);
  }, [clientForm]);

  useEffect(
    function setInititalVisibleSteps() {
      setVisibleSteps(clientForm?.form.sections.filter((section) => !!section.visible) ?? []);
    },
    [clientForm?.form],
  );

  const visibleStepsIds = useMemo(() => visibleSteps.map((x) => x.id), [visibleSteps]);

  // Make sure they're not starting on a section they don't have permission to view
  useEffect(
    function setInitialCurrentStep() {
      if (correctedStepIndex || !clientForm || !visibleSteps.length) {
        return;
      }

      // once the visibleSteps/clientform loads, we assume we corrected the index of the step (if needed)
      // doing this instead of a blank dependenscy array because they might not load quick enough
      setCorrectedStepIndex(true);

      if (clientForm.type !== FormType.Resource && savedStepId !== null) {
        const savedStepIndex = visibleSteps.findIndex((x) => x.id === savedStepId);

        if (savedStepIndex > -1) {
          setCurrentStepIndex(savedStepIndex);
          return;
        }
      }

      const visibleStepsStatuses = visibleSteps.map((step) => {
        const status = clientForm.sectionStatuses.find((x) => x.templateFormSectionId === step.id)?.status;
        return { status, anyActionsVisible: step.actions.every((x) => x.visible) };
      });
      const index = visibleStepsStatuses.findIndex(
        (step) => step.anyActionsVisible && step.status !== ClientFormSectionStatus.Completed && step.status !== ClientFormSectionStatus.Approved,
      );
      const fallbackIndex = 0;
      setCurrentStepIndex(index > -1 ? index : fallbackIndex);
    },
    [
      clientForm,
      clientForm?.currentSteps,
      clientForm?.form.sections,
      correctedStepIndex,
      currentStepIndex,
      savedStepId,
      setSavedStepId,
      visibleSteps,
    ],
  );

  useEffect(() => {
    setCorrectedStepIndex(false);
  }, [formId]);

  useEffect(
    function setHighlightedStep() {
      if (!toHighlight || !visibleSteps.length) {
        return;
      }
      const highlightStepIndex = visibleSteps.findIndex((x) => x.id === toHighlight.sectionId);
      if (highlightStepIndex > -1) {
        setCurrentStepIndex(highlightStepIndex);
      }
    },
    [toHighlight, visibleSteps],
  );

  useEffect(() => {
    const handler = (evt: ActionHighlightInfo | null) => {
      if (!evt || !visibleSteps.length) {
        return;
      }
      const highlightStepIndex = visibleSteps.findIndex((x) => x.id === evt.sectionId);
      if (highlightStepIndex > -1) {
        setCurrentStepIndex(highlightStepIndex);
      }
    };

    EventSystem.listen('highlight-question', handler);

    return () => {
      EventSystem.stopListening('highlight-question', handler);
    };
  }, [visibleSteps]);

  const getActivityFeed = useCallback(
    (pageNumber = 1) => {
      ActivityFeedService.getActivityFeed({ clientFormId: formId, pageNumber: pageNumber }).then((res) => {
        setActivityFeed((prev) => {
          const feedPaged = { ...res };
          if (prev.data && pageNumber > 1) {
            feedPaged.data = [...prev.data, ...res.data];
          }
          return feedPaged;
        });
      });
    },
    [formId],
  );

  useEffect(() => {
    getActivityFeed();
  }, [getActivityFeed, currentLanguage, clientForm?.dueDateUtc, clientForm?.effectiveDateUtc]);

  const onProgressChangeEvent = useCallback(
    (e: ProgressUpdatedEvent) => {
      if (e.clientFormId === formId) {
        setFormProgress(e.clientFormProgress);
        setStepProgress(e.sectionProgress);
      }
    },
    [formId],
  );

  useEffect(
    function onFormProgressUpdated() {
      EventSystem.listen('form-progress-updated', onProgressChangeEvent);

      return () => {
        EventSystem.stopListening('form-progress-updated', onProgressChangeEvent);
      };
    },
    [onProgressChangeEvent],
  );

  const currentFormStepUser = useMemo(
    () => ClientFormService.getCurrentFormUser(formSectionUsers, currentUser as User, currentTemplateStep?.id),
    [formSectionUsers, currentUser, currentTemplateStep?.id],
  );

  const canEditForm = useMemo(() => {
    if (!clientForm) {
      return false;
    }

    if (formStatus === ClientFormStatus.Completed) {
      return false;
    }

    // When submitted for validation - allow Validators, Consultants, ConsultantManagers, SuperAdmins, Team Leads and Team Members to edit the form
    if (
      formStatus === ClientFormStatus.SubmittedForValidation &&
      (hasFormRole(ClientFormUserRole.Validator, currentFormUser) || hasPermission(Roles.TeamMember))
    ) {
      return true;
    }

    // If they are a contributor on the step, allow
    if (hasFormRole([ClientFormUserRole.Contributor, ClientFormUserRole.Approver], currentFormStepUser)) {
      return true;
    }

    return (
      hasPermission(Roles.TeamMember) ||
      (clientForm.type === FormType.Asset || clientForm.type === FormType.SubFormWithApproval
        ? hasFormRole(
            [ClientFormUserRole.Owner, ClientFormUserRole.Validator, ClientFormUserRole.Approver, ClientFormUserRole.Contributor],
            currentFormUser,
          ) &&
          (formStatus === ClientFormStatus.NotStarted || formStatus === ClientFormStatus.InProgress)
        : hasFormRole([ClientFormUserRole.Owner, ClientFormUserRole.Contributor], currentFormUser))
    );
  }, [clientForm, formStatus, currentFormUser, hasPermission, currentFormStepUser]);

  const onUsersChange = useCallback(() => {
    getActivityFeed();
  }, [getActivityFeed]);

  const onRename = useCallback((newSubtitle: string) => {
    setClientForm((prev) => (prev === null ? null : { ...prev, subtitle: newSubtitle }));
  }, []);

  const onAccessTypeChange = useCallback((value: Access) => {
    setClientForm((prev) => (prev === null ? null : { ...prev, accessType: value }));
  }, []);

  useEffect(
    function updateFormRendererCurrentStep() {
      if (!clientForm || !correctedStepIndex) {
        return;
      }

      nextTick(() => {
        formRenderer.current?.changeStep(currentStepIndex);
      });
    },
    [clientForm, correctedStepIndex, currentStepIndex, setSavedStepId],
  );

  useEffect(
    function saveCurrentStep() {
      if (!correctedStepIndex || !currentTemplateStep) {
        return;
      }

      setSavedStepId(currentTemplateStep.id);
    },
    [correctedStepIndex, currentTemplateStep, setSavedStepId],
  );

  const isCurrentSectionValid = useMemo(() => {
    if (!currentTemplateStep) {
      return false;
    }
    return (
      isFormSectionValid[currentTemplateStep.id] &&
      stepProgress[currentTemplateStep.id].totalRequired <= stepProgress[currentTemplateStep.id].totalAnswers
    );
  }, [currentTemplateStep, isFormSectionValid, stepProgress]);

  useEffect(() => {
    if (!clientForm) {
      return;
    }

    const doHighlight = () => {
      if (!formRenderer.current) {
        setTimeout(doHighlight, 100);
        return;
      }
      formRenderer.current?.highlight(toHighlight);
    };

    setTimeout(doHighlight, 2000);
  }, [clientForm, toHighlight]);

  const onCurrentUserUpdate = useCallback(
    (user: ClientFormUser | null, reloadForm?: boolean) => {
      setCurrentFormUser(user);
      reloadForm && fetchForm(); // permissions changed, need to refresh form logic
    },
    [fetchForm],
  );

  const showCompleteButton = useMemo(() => {
    return (
      currentTemplateStep?.triggersNextStep &&
      !currentTemplateStep?.requiresApproval &&
      currentStepStatus.status === ClientFormSectionStatus.NotSubmitted
    );
  }, [currentTemplateStep, currentStepStatus.status]);

  const showSubmitButton = useMemo(() => {
    return currentTemplateStep?.requiresApproval && currentStepStatus.status === ClientFormSectionStatus.NotSubmitted;
  }, [currentTemplateStep?.requiresApproval, currentStepStatus.status]);

  const showApproveButton = useMemo(() => {
    return currentTemplateStep?.requiresApproval && currentStepStatus.status === ClientFormSectionStatus.Submitted;
  }, [currentTemplateStep?.requiresApproval, currentStepStatus.status]);

  const latestProgressEvent = useRef<ProgressUpdatedEvent | null>(null);
  useEffect(() => {
    latestProgressEvent.current = {
      clientFormId: clientForm?.id || '',
      clientFormProgress: formProgress,
      sectionProgress: stepProgress,
    };
  }, [clientForm?.id, formProgress, stepProgress]);

  const refetchUsers = useFormUsers();

  const submitStep = useCallback(
    (status: ClientFormSectionStatus, silent = false) => {
      if (clientForm && currentTemplateStep) {
        ClientFormService.changeSectionStatus(clientForm.id, currentTemplateStep.id, status).then((res) => {
          const removedQuestions = res.data.workflowTriggerResponse.removedSections
            .map((removed) => clientForm.form.sections.find((x) => x.id == removed))
            .filter((x) => x)
            .map((x) => x?.actions ?? [])
            .flat();

          let newAnswers = ObjectUtils.DeepClone(clientForm.form.answers);
          for (const question of removedQuestions) {
            delete newAnswers[question.id];
          }

          if (res.data) {
            formRenderer.current?.onStatusChanged(res.data.workflowTriggerResponse);

            const flattenedAddedAnswers = Object.keys(res.data.workflowTriggerResponse.addedAnswers).reduce(
              (acc: Record<string, any>, key: string) => {
                acc[key] = res.data.workflowTriggerResponse.addedAnswers[key].data;
                return acc;
              },
              {},
            );

            newAnswers = { ...newAnswers, ...flattenedAddedAnswers };

            setVisibleSteps((prev) => [
              ...prev.filter((step) => !res.data.workflowTriggerResponse.removedSections.includes(step.id)),
              ...res.data.workflowTriggerResponse.addedSections,
            ]);

            for (const step of res.data.workflowTriggerResponse.addedSections || []) {
              setStepProgress((prev) => ({ ...prev, [step.id]: { totalAnswers: step.requiredAnswers, totalRequired: step.requiredActions } }));
            }
            nextTick(
              () =>
                latestProgressEvent.current &&
                EventSystem.fireEvent('form-progress-updated', {
                  ...latestProgressEvent.current,
                  clientFormProgress: {
                    totalAnswers: res.data.totalRequiredAnswers,
                    totalRequired: res.data.totalRequiredActions,
                  },
                }),
            );
            const updatedPlaceholders = {} as Record<string, ActionPlaceholderData>;
            const existingPlaceholders = clientForm?.placeholders || {};
            if (existingPlaceholders) {
              for (const placeholder of Object.keys(existingPlaceholders)) {
                if (!res.data.placeholders.removedLookups || !res.data.placeholders.removedLookups[placeholder]) {
                  updatedPlaceholders[placeholder] = existingPlaceholders[placeholder];
                }
              }
            }
            const clientFormUpdated = {
              ...clientForm,
              sectionStatuses: deDuplicate(
                [
                  res.data,
                  ...clientForm.sectionStatuses.map((sectionStatus) => {
                    if (sectionStatus.templateFormSectionId === currentTemplateStep.id) {
                      return { ...sectionStatus, ...res.data };
                    }
                    const addedSection = res.data.workflowTriggerResponse.addedSections.find((x) => x.id === sectionStatus.templateFormSectionId);
                    if (addedSection && addedSection.info) {
                      return { ...sectionStatus, ...addedSection.info };
                    }
                    return sectionStatus;
                  }),
                ],
                'templateFormSectionId',
              ),
              currentSteps: [...res.data.workflowTriggerResponse.currentSteps],
              nextSteps: status !== ClientFormSectionStatus.Submitted ? [] : [...clientForm.nextSteps],
              form: {
                ...clientForm.form,
                answers: newAnswers,
                sections: [
                  ...clientForm.form.sections.map((section) => {
                    const addedSection = res.data.workflowTriggerResponse.addedSections.find((s) => s.id === section.id);
                    const isRemovedSection = res.data.workflowTriggerResponse.removedSections.includes(section.id);
                    return isRemovedSection ? ({ id: section.id, visible: false, actions: [] } as any) : addedSection || section;
                  }),
                ],
              },
              placeholders: { ...updatedPlaceholders, ...res.data.placeholders.addedLookups },
              allSectionsValid: res.data.allSectionsValid,
            };

            setClientForm(clientFormUpdated);

            if (!silent) {
              toastsRef.current.addToast({
                title: t(`${sectionStatusToasterKeys[status]}.title`, { type: formTypeText }),
                type: ToastType.SUCCESS,
                description: t(`${sectionStatusToasterKeys[status]}.text`, { type: formTypeText }),
                expiresInMs: 5000,
              });

              if (
                currentStepIndex < clientFormUpdated.form.sections.filter((x) => x.visible).length - 1 &&
                status !== ClientFormSectionStatus.Submitted
              ) {
                setCurrentStepIndex((prev) => prev + 1);
              }
            }
            if (res.data.workflowTriggerResponse.addedSections) {
              refetchUsers(clientForm.id);
            }
          }
        });
      }
    },
    [clientForm, currentStepIndex, currentTemplateStep, formTypeText, refetchUsers, t],
  );

  useEffect(
    function autoSubmitNonRequiredStep() {
      if (!currentTemplateStep || currentTemplateStep.requiresApproval || currentStepStatus?.status !== ClientFormSectionStatus.NotSubmitted) {
        return;
      }

      if (stepProgress[currentTemplateStep.id].totalRequired !== 0) {
        return;
      }

      submitStep(ClientFormSectionStatus.Completed, true);
    },
    [currentStepStatus?.status, currentTemplateStep, stepProgress, submitStep],
  );

  // open comments via url/event
  const [selectedStepFromEvent, setSelectedStepFromEvent] = useState<OpenFormSectionEvent | undefined>();
  useEffect(
    function changeSectionIndexOnEvent() {
      const handler = (event: OpenFormSectionEvent) => {
        const index = visibleSteps.findIndex((x) => x.id === event.sectionId) ?? -1;
        if (!event.sectionId || index === -1) {
          return;
        }

        setCurrentStepIndex(index);
        setSelectedStepFromEvent(event);
      };

      EventSystem.listen('open-form-section', handler);
      return () => {
        EventSystem.stopListening('open-form-section', handler);
        setSelectedStepFromEvent(undefined);
      };
    },
    [visibleSteps],
  );

  const isSuspense = useIsInSuspense((x) => x.value);

  useEffect(() => {
    if (!isSuspense && selectedStepFromEvent) {
      if (selectedStepFromEvent.sourceId) {
        const result = setTimeout(() => {
          EventSystem.fireEvent('question-comment-open', {
            sourceId: selectedStepFromEvent.sourceId || '',
            sectionId: selectedStepFromEvent.sectionId,
          });
        }, 100);
        return () => clearTimeout(result);
      }
    }
  }, [isSuspense, selectedStepFromEvent]);

  const onChecklistChanged = useCallback((checklist: PeriodicReviewChecklistItemInstance[]) => {
    setClientForm(
      (prev) =>
        prev && {
          ...prev,
          periodicReviewChecklist: checklist,
        },
    );
  }, []);

  const { associations } = useFetchAssociations(clientForm?.id, true);

  const isDocumentFile = useMemo(
    () => clientForm?.type === FormType.Document && clientForm?.form.isSystem,
    [clientForm?.form.isSystem, clientForm?.type],
  );

  const rightPaneRef = useRef<HTMLDivElement>(null);
  const leftPaneRef = useRef<HTMLDivElement>(null);
  const docViewerMaxWidth = useMemo(
    () =>
      `calc(100vw - ${
        (leftNavRef.current?.offsetWidth || 75) +
        (leftNavCollapsed ? 0 : leftPaneRef.current?.offsetWidth || 300) +
        (commentsCollapsed ? 0 : rightPaneRef.current?.offsetWidth || 344)
      }px)`,
    [commentsCollapsed, leftNavCollapsed, leftNavRef],
  );

  const documentViewerStyle = useMemo(() => ({ maxWidth: docViewerMaxWidth }), [docViewerMaxWidth]);

  useEffect(() => {
    const handler = (answer: AnswerResponse & { type: ActionTypeNames }) => {
      if (!['SingleFileUploadAction', 'MultiFileUploadAction', 'FileOrLinkUploadAction'].includes(answer.type)) {
        return;
      }

      ClientFormService.getAttachments(clientForm?.id as string).then((res) => {
        setClientForm((prev) => prev && { ...prev, attachments: res.data });
      });
    };

    EventSystem.listen('form-answer', handler);

    return () => {
      EventSystem.stopListening('form-answer', handler);
    };
  }, [clientForm?.id]);

  const cantSubmitReason = useMemo(() => {
    if (!canSubmitStep) {
      const step = visibleSteps[currentStepIndex];
      return t('common:form-header.incomplete-step-approval', {
        step: LanguageUtils.getTranslation('title', step?.translations || {}),
      });
    }

    if (hasFormRole(ClientFormUserRole.Approver, currentFormStepUser) || hasFormRole(ClientFormUserRole.Approver, currentFormUser)) {
      return t('common:form-header.approver-cannot-submit');
    }

    return null;
  }, [canSubmitStep, currentFormStepUser, currentFormUser, currentStepIndex, t, visibleSteps]);

  const cantApproveReason = useMemo(() => {
    const step = visibleSteps[currentStepIndex];
    if (!canSubmitStep) {
      return t('common:form-header.incomplete-step', {
        step: LanguageUtils.getTranslation('title', step?.translations || {}),
      });
    }

    if (!hasFormRole(ClientFormUserRole.Approver, currentFormStepUser)) {
      return t('common:form-header.non-approver-cannot-approve-step', {
        step: LanguageUtils.getTranslation('title', step?.translations || {}),
      });
    }

    return null;
  }, [canSubmitStep, currentFormStepUser, currentStepIndex, t, visibleSteps]);

  const [allFlaggedRisks, setAllFlaggedRisks] = useState<Risk[]>([]);
  useEffect(() => {
    if (clientForm && !allFlaggedRisks.length) {
      RiskService.getRisks(clientForm?.clientId, { clientFormIds: [clientForm?.id], pageSize: 9999, pageNumber: 1 }).then((res) => {
        const allActions = clientForm.form.sections.reduce((acc: FormAction[], section: FormSection) => {
          acc.push(...section.actions);
          return acc;
        }, []);
        setAllFlaggedRisks(
          res.data.filter((x) => x.isValid).map((risk) => ({ ...risk, actionIndex: allActions.findIndex((x) => x.id === risk.actionId) })),
        );
      });
    }
  }, [allFlaggedRisks.length, clientForm]);

  useEffect(() => {
    const handle = (event: QuestionRiskCreatedEvent) => {
      setAllFlaggedRisks((prev) => {
        const existingRiskIndex = prev.findIndex((risk) => risk.id === event.risk.id);

        if (existingRiskIndex !== -1) {
          return prev.map((risk, index) => (index === existingRiskIndex ? event.risk : risk));
        } else {
          return [...prev, event.risk];
        }
      });
    };
    EventSystem.listen('question-risk-created', handle);
    return () => EventSystem.stopListening('question-risk-created', handle);
  }, []);

  const onSetEffectiveDate = useCallback((value: Date | null) => {
    setClientForm((prev) => prev && { ...prev, effectiveDateUtc: value?.toISOString() ?? null });
  }, []);

  const onSetDueDate = useCallback((value: Date | null) => {
    setClientForm((prev) => prev && { ...prev, dueDateUtc: value?.toISOString() ?? null });
  }, []);

  const onDistributionUpdated = useCallback((distribution: DistributionResponse) => {
    setClientForm(
      (prev) =>
        prev && {
          ...prev,
          distribution: distribution,
        },
    );
  }, []);

  useEffect(() => {
    const handler = (evt: DistributionResponse) => {
      onDistributionUpdated(evt);
    };

    EventSystem.listen('distribution-updated', handler);
    return () => {
      EventSystem.stopListening('distribution-updated', handler);
    };
  }, [onDistributionUpdated]);

  useEffect(() => {
    const handler = (evt: { id: string; signed: boolean }) => {
      if (evt.id !== clientForm?.id) return;

      setClientForm(
        (prev) =>
          prev &&
          ({
            ...prev,
            distribution: {
              ...(prev as DocumentResponse).distribution,
              members: (prev as DocumentResponse).distribution.members.map((x) =>
                x.memberId !== currentUser?.id
                  ? x
                  : {
                      ...x,
                      status: evt.signed ? DistributionMemberStatus.Signed : DistributionMemberStatus.Acknowledged,
                      statusUtc: new Date().toISOString(),
                    },
              ),
            },
          } as DocumentResponse),
      );
    };

    EventSystem.listen('acknowledged', handler);
    return () => {
      EventSystem.stopListening('acknowledged', handler);
    };
  }, [clientForm?.id, currentUser?.id]);

  return (
    <div className="flex h-full min-h-full flex-col">
      {clientForm && (
        <TopNavPortal>
          {clientForm.form.type === FormType.Document && <DocumentBreadCrumb clientForm={clientForm} />}
          {clientForm.form.type !== FormType.Document && <FormBreadCrumb clientForm={clientForm} />}
        </TopNavPortal>
      )}
      <div className="flex h-full flex-col">
        <PageLoader loading={loading && (!isSysTemplate || correctedStepIndex)}>
          {!clientForm && (
            <div className="bg-gray-5 flex h-full items-center justify-center">
              <Heading size={HeadingSize.H3}>{t('form:not-found.title')}</Heading>
            </div>
          )}
          {!!clientForm && (
            <FormHeader
              onRename={onRename}
              editMode={true}
              formProgress={formProgress}
              clientForm={clientForm}
              startDate={formStartDate}
              formStatus={formStatus}
              onSetDueDate={onSetDueDate}
              onCurrentUserUpdate={onCurrentUserUpdate}
              onUsersChange={onUsersChange}
              onStatusChanged={fetchForm}
              hideMainFormActions={!hasPermission(Roles.Employee) && !currentFormUser}
              allSteps={visibleSteps}
              allStepInfos={clientForm?.sectionStatuses ?? []}
              currentStep={clientForm?.currentSteps[0]}
              onChecklistChanged={onChecklistChanged}
              associations={associations}
              onFileReplaced={fetchForm}
              onAccessTypeChange={onAccessTypeChange}
              onSetEffectiveDate={onSetEffectiveDate}
              scrolledToBottom={bottomDetectorVisible}
            />
          )}
          <div className="relative flex flex-1 overflow-y-auto overflow-x-hidden pr-1">
            <div>
              {clientForm && (
                <div className="relative h-full">
                  <div
                    ref={leftPaneRef}
                    className={`sticky top-0 h-full w-[300px] overflow-y-auto border-r transition-[margin] duration-300 ${
                      leftNavCollapsed ? '-ml-[285px]' : 'mr-0'
                    }`}
                  >
                    <ViewerLeftNav
                      clientForm={clientForm}
                      onStepChange={setCurrentStepIndex}
                      currentStepIndex={currentStepIndex}
                      visibleSteps={visibleSteps}
                      visibleStepsIds={visibleStepsIds}
                      stepProgress={stepProgress}
                      fetchForm={fetchForm}
                      associations={associations}
                      onChecklistChanged={onChecklistChanged}
                      allFlaggedRisks={allFlaggedRisks}
                      onDistributionUpdated={onDistributionUpdated}
                    />
                  </div>
                  <ChevronIcon
                    onClick={() => setLeftNavCollapsed((prev) => !prev)}
                    type={leftNavCollapsed ? ChevronType.RIGHT : ChevronType.LEFT}
                    className={`hover:bg-gray-5 absolute -right-3 top-4 z-[40] h-6 w-6 rounded-full border bg-white transition duration-500 ease-in-out`}
                  />
                </div>
              )}
            </div>
            <div className={`flex w-full flex-col overflow-y-auto ${isDocumentFile ? '' : 'pb-8'}`}>
              {!!clientForm && (
                <FormRendererDesignContext.Provider
                  value={{
                    ...FormRendererDesignDefaults,
                    slots: {
                      ...FormRendererDesignDefaults.slots,
                      sectionTitleEnd: (!currentTemplateStep?.requiresApproval
                        ? stepProgress && stepProgress[currentTemplateStep?.id]?.totalRequired > 0
                        : true) && (
                        <>
                          {(showCompleteButton || showApproveButton || showSubmitButton) && (
                            <div>
                              {showCompleteButton && (
                                <Tooltip text={cantSubmitReason}>
                                  {(tooltip) => (
                                    <div {...tooltip}>
                                      <Button
                                        type={ButtonType.PRIMARY}
                                        size={ButtonSize.S}
                                        onClick={() => submitStep(ClientFormSectionStatus.Completed)}
                                        disabled={
                                          !canEditForm ||
                                          !canSubmitStep ||
                                          !canCurrentUserSubmitSection(currentFormStepUser, clientForm.clientId, currentUser) ||
                                          !isCurrentSectionValid
                                        }
                                        data-cy="navigation-complete"
                                      >
                                        {t('form:navigation.complete')}
                                      </Button>
                                    </div>
                                  )}
                                </Tooltip>
                              )}
                              {showSubmitButton && (
                                <Tooltip text={cantSubmitReason}>
                                  {(tooltip) => (
                                    <div {...tooltip}>
                                      <Button
                                        type={ButtonType.PRIMARY}
                                        size={ButtonSize.S}
                                        onClick={() => submitStep(ClientFormSectionStatus.Submitted)}
                                        disabled={
                                          !canEditForm ||
                                          !canSubmitStep ||
                                          (!canCurrentUserSubmitSection(currentFormStepUser, clientForm.clientId, currentUser) &&
                                            !canCurrentUserSubmitSection(currentFormUser, clientForm.clientId, currentUser)) ||
                                          !isCurrentSectionValid ||
                                          hasFormRole(ClientFormUserRole.Approver, currentFormStepUser) ||
                                          hasFormRole(ClientFormUserRole.Approver, currentFormUser)
                                        }
                                        data-cy="navigation-submit"
                                      >
                                        {t('form:navigation.submit')}
                                      </Button>
                                    </div>
                                  )}
                                </Tooltip>
                              )}
                              {showApproveButton && (
                                <Tooltip text={cantApproveReason}>
                                  {(tooltip) => (
                                    <div {...tooltip}>
                                      <Button
                                        type={ButtonType.PRIMARY}
                                        size={ButtonSize.S}
                                        onClick={() => submitStep(ClientFormSectionStatus.Approved)}
                                        disabled={
                                          !canSubmitStep ||
                                          !canCurrentUserApproveSection(currentFormStepUser, clientForm.clientId, currentUser) ||
                                          !isCurrentSectionValid ||
                                          !hasFormRole(ClientFormUserRole.Approver, currentFormStepUser)
                                        }
                                        data-cy="navigation-approve"
                                      >
                                        {t('form:navigation.approve')}
                                      </Button>
                                    </div>
                                  )}
                                </Tooltip>
                              )}
                            </div>
                          )}
                          <div>
                            <span>
                              {currentStepStatus.status !== ClientFormSectionStatus.NotSubmitted && (
                                <Trans
                                  t={t}
                                  i18nKey="form:titles.status-changed-by"
                                  components={{ Bold: <span className="font-medium" /> }}
                                  values={{
                                    status: t(sectionStatusKeys[currentStepStatus.status]),
                                    date: DateUtils.formatDate(new Date(currentStepStatus.statusUtc || '')),
                                    fullname: currentStepStatus.statusChangedBy ?? t('form:titles.public-user'),
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </>
                      ),
                    },
                  }}
                >
                  {!viewDistributionLogs && (
                    <>
                      {!clientForm.form.isSystem && (
                        <>
                          <FormRendererV2
                            clientForm={clientForm}
                            initialMode={FormRendererMode.EditView}
                            canEdit={canEditForm}
                            stepStatuses={clientForm.sectionStatuses}
                            stepRendererModes={stepRendererModes}
                            ref={formRenderer}
                            moduleId={clientForm?.clientModuleId}
                            moduleSectionId={clientForm?.clientModuleSectionId}
                            isCommentsVisible={true}
                            allFlaggedRisks={allFlaggedRisks}
                          />
                        </>
                      )}
                      {isDocumentFile && selectedFile && !loadingFile && (
                        <DocumentFileViewer
                          key={`${selectedFile}${docViewerMaxWidth}`}
                          uri={selectedFile}
                          type={(clientForm as DocumentResponse).fileFormat}
                          style={documentViewerStyle}
                        />
                      )}
                      {isDocumentFile && !selectedFile && !loadingFile && (
                        <div className="flex h-full flex-col items-center justify-center text-center">
                          <div className="text-dpm-20">{t('document-not-found.title')}</div>
                          <div>{t('document-not-found.subtitle')}</div>
                        </div>
                      )}
                      {bottomDetector}
                    </>
                  )}
                  {viewDistributionLogs && clientForm && (
                    <DistributionLogs
                      documentTitle={FormUtils.getDocumentTitle(clientForm as DocumentResponse)}
                      distribution={clientForm.distribution}
                    />
                  )}
                </FormRendererDesignContext.Provider>
              )}
            </div>
            {!!clientForm && (
              <div className="relative h-full">
                <ChevronIcon
                  onClick={() => setCommentsCollapsed((prev) => !prev)}
                  type={!commentsCollapsed ? ChevronType.RIGHT : ChevronType.LEFT}
                  className={`hover:bg-gray-5 absolute -left-3 top-4 z-[40] h-6 w-6 rounded-full border bg-white transition duration-500 ease-in-out`}
                />

                <div
                  ref={rightPaneRef}
                  className={`mx-auto flex h-full w-[24rem] flex-shrink-0 flex-col overflow-auto transition-[margin] duration-300 ${
                    commentsCollapsed ? '-mr-[23rem]' : 'mr-0'
                  }`}
                >
                  <div className="bg-background-1 flex-grow border-l pt-2">
                    {!commentsCollapsed && (
                      <TabStrip evenTabs enableSticky>
                        <TabStrip.TabHeader id="comments" text={t('form:tabs.comments')} value={null}>
                          <TabStrip.TabHeader.Slot name="Trailing">
                            {unreadMainComments > 0 && (
                              <div className="text-dpm-12 flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-white">
                                {unreadMainComments}
                              </div>
                            )}
                          </TabStrip.TabHeader.Slot>
                        </TabStrip.TabHeader>
                        <TabStrip.TabHeader id="activity" text={t('form:tabs.activity')} value={null} />
                        <TabStrip.TabContent forId="comments">
                          <div className="tab-scroll h-full overflow-y-auto">
                            <SignalRCommentProvider clientFormId={formId as string}>
                              {visibleSteps[currentStepIndex]?.id && (
                                <Comments
                                  formStepId={visibleSteps[currentStepIndex]?.id}
                                  clientFormId={formId as string}
                                  disabled={!!clientForm.originId}
                                />
                              )}
                            </SignalRCommentProvider>
                          </div>
                        </TabStrip.TabContent>
                        <TabStrip.TabContent forId="activity">
                          <div className="tab-scroll overflow-y-auto">
                            <ActivityFeed feed={activityFeed} onLoadMore={getActivityFeed} />
                          </div>
                        </TabStrip.TabContent>
                      </TabStrip>
                    )}
                  </div>
                </div>
              </div>
            )}
            <RiskModal />
          </div>
        </PageLoader>
      </div>
    </div>
  );
};

export default FormPage;
